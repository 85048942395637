/**
 * 对Date的扩展，将 Date 转化为指定格式的String
 * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2 个占位符
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * eg:
 * (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 * (new Date()).Format("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 * (new Date()).Format("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 * (new Date()).Format("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 * (new Date()).Format("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 */

import moment from "moment";
/**
 * JS获取星期几
 * @param {number} timestamp                目标时间戳，不带毫秒
 * @param  {boolean} isChinese              是否中文
 * @return {String}                         返回指定格式的星期N字符串
 * 
 * 中文："日一二三四五六"
 * 数字：0123456
 */
 export function getweektime(timestamp,isZhou){
    var weeks = new Array("日", "一", "二", "三", "四", "五", "六");
    var now = new Date(timestamp);
    var day = now.getDay();
    var week = isZhou?'周'+ weeks[day]: '星期'+ weeks[day];
    return week;
}
export function Format(time, fmt = "yyyy/MM/DD HH:mm:ss"){
  // console.log(time, fmt)
  var date = moment(time).format(fmt);
  // console.log(date);
  return date
}

export function Formats(time, fmt = "yyyy/MM/dd HH:mm:ss") {
  time = new Date(time);
  var o = {
    "M+": time.getMonth() + 1, //月份
    "d+": time.getDate(), //日
    "h+": time.getHours() % 12 == 0 ? 12 : time.getHours() % 12, //小时
    "H+": time.getHours(), //小时
    "m+": time.getMinutes(), //分
    "s+": time.getSeconds(), //秒
    "q+": Math.floor((time.getMonth() + 3) / 3), //季度
    S: time.getMilliseconds() //毫秒
  };
  var week = {
    "0": "/u65e5",
    "1": "/u4e00",
    "2": "/u4e8c",
    "3": "/u4e09",
    "4": "/u56db",
    "5": "/u4e94",
    "6": "/u516d"
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (time.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (RegExp.$1.length > 1? RegExp.$1.length > 2? "/u661f/u671f": "/u5468": "") + week[time.getDay() + ""]
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt
}

/**
 * @description: 深克隆
 * @param {Object} obj1 对象1
 * @param {Object} obj2 对象2
 * @return:
 * @author: wangchaoxu
 */
 export function cloneDeep(obj1, obj2) {
  for (var key in obj2) {
    obj1[key] =
      obj1[key] && obj1[key].toString() === "[object Object]"
        ? cloneDeep(obj1[key], obj2[key])
        : (obj1[key] = obj2[key]);
  }
  return obj1;
}


//阿拉伯数字转中文数字
export function noToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
      alert("Number is wrong!");
      return "Number is wrong!";
  }
  var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
  var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
  var a = ("" + num).replace(/(^0*)/g, "").split("."),
      k = 0,
      re = "";
  for (var i = a[0].length - 1; i >= 0; i--) {
      switch (k) {
          case 0:
              re = BB[7] + re;
              break;
          case 4:
              if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                  re = BB[4] + re;
              break;
          case 8:
              re = BB[5] + re;
              BB[7] = BB[5];
              k = 0;
              break;
      }
      if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
      if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
      k++;
  }
  if (a.length > 1) //加上小数部分(如果有小数部分)
  {
      re += BB[6];
      for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
  }
  return re;
}
export function gettime(date){
  let time = new Date(date)
  let Y=time.getFullYear()
  let M= time.getMonth() + 1 //月份
  let d= time.getDate() //日
  let h= time.getHours() //小时
  let m= time.getMinutes() //分
  let s= time.getSeconds() //秒
  // let q= Math.floor((time.getMonth() + 3) / 3) //季度
  let S= time.getMilliseconds() //毫秒
if(M<10){
  M='0'+M
}
if (d<10) {
  d='0'+d
}
if(h<10){
  h='0'+h
}
if(m<10){
  m='0'+m
}
if(s<10){
  s='0'+s
}
if(S<10){
  S='00'+S
}else if(S<100){
  S='0'+S
}
  return `${Y}-${M}-${d}T${h}:${m}:${s}.${S}Z`
}